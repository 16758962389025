.login__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
}

.login__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: black;
    border-radius: 10px;
}

.login__google {
    background-color: #DC4E41;
}

.login__facebook {
    background-color: #3B5998;
}

.login div {
    margin-top: 7px;
}