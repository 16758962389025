.reset__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .reset__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: black;
    border-radius: 10px;
  }
  
  .reset div {
    margin-top: 7px;
  }